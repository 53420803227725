import React, { useReducer, useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import axios from 'axios';
import Logo from '../images/dinggo-logo.inline.svg';
import '../assets/styles/base/standard-page.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';

const ForgotPassword = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        email
      }
      forgotpassword {
        recaptcha {
          siteKey
        }
        seo {
          title
          canonical
          description
          og {
            description
            image
            title
            type
            url
          }
        }
      }
    }
  `);
  
  const { seo, recaptcha } = data.forgotpassword;
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [captcha, setCaptcha] = useState(null);
  const [ForgotPasswordDetails, setForgotPasswordDetails] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      username: ''
    }
  );
  
  const addScript = (url) => {
    const script = window.document.createElement('script');
    script.src = url;
    script.async = true;
    window.document.body.appendChild(script);
  };
  
  if (typeof window !== 'undefined') {
    window.ReCaptchaCallbackV3 = () => {
      grecaptcha.ready(() => {
        setCaptcha(grecaptcha);
      });
    };
    
    if (typeof window.grecaptcha !== 'object') {
      addScript(`https://www.google.com/recaptcha/api.js?onload=ReCaptchaCallbackV3&render=${recaptcha.siteKey}`);
    }
  }
  
  useEffect(() => {
    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    return () => {
      if (typeof window.grecaptcha === 'object') {
        window.grecaptcha = undefined;
      }
    }
  }, []);
  
  const handleInputChange = (event) => {
    let { name, value } = event.target;
    setForgotPasswordDetails({ [name]: value });
  };

  const hasForgotPasswordDetails = () => {
    let valid = true;
    if (ForgotPasswordDetails.username.length === 0) {
      valid = false;
    }

    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccessMessage(false);
    setErrorMessage(false);

    if (hasForgotPasswordDetails()) {
      if (captcha !== null) {
        grecaptcha.execute(recaptcha.siteKey, { action: 'forgotpassword' }).then((token) => {
          ForgotPasswordDetails.token = token;
          axios.post(`${process.env.GATSBY_PORT}endpoints/forgotpasswordform`, ForgotPasswordDetails).then(res => {
            const { data } = res;
            if (data.success) {
              setSuccessMessage('If the username exists, the password reset token will be sent to the registered email.');
            } else {
              //setErrorMessage(data.data.message);
              setErrorMessage('Something goes wrong.');
            }
          });
        });
      }
    } else {
      console.warn('Google recaptcha failed to load');
    }
  };

  return (
    <Layout hideHeaderFooter={false}>
      <section className="standard-page bg-light py-5 vh-100 d-flex align-items-center">
        <SEO
          title={seo.title}
          description={seo.title}
          canonical={seo.canonical}
          stucturedData=''
          ogTitle={seo.og.title}
          ogDescription={seo.og.description}
          ogImage={seo.og.image}
          ogType={seo.og.type}
          ogUrl={seo.og.url}
        />
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-6 mb-4">
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-5">
              <div className="card shadow-sm">
                <form className="card-body" onSubmit={(e) => { handleSubmit(e); }}>
                <input type="hidden" name="recaptcha_key" value={recaptcha.siteKey} />
                  <h3 className="text-center py-4 mt-0">Forgot your password</h3>
                  <div className="form-group">
                    <label htmlFor="name">Username / Email</label>
                    <input type="text" className="form-control" id="username" name="username" onChange={(e) => { handleInputChange(e); }} value={ForgotPasswordDetails.username} />
                  </div>
                  {successMessage.length > 0 && (
                    <p>{successMessage}</p>
                  )}
                  {errorMessage.length > 0 && (
                    <p className="error">{errorMessage}</p>
                  )}
                  <button className={`btn btn-block btn-lg ${hasForgotPasswordDetails() ? '' : 'disable'}`} type="submit">Forgot Password</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ForgotPassword;
